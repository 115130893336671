
import React from 'react';

import MobileRouter from '../Routers/MobileRouter';
import HeaderMobile from '../../Mobile/HeaderMobile';
import Footer from '../Footer';

const Mobile = () => {

  const styles = {
    container: {
      backgroundPosition: 'center',
      backgroundRepeat: 'no-repeat',
      backgroundSize: 'cover',
      minHeight: '100%',
      width: '100%',
      height: 'auto',
      top: 0,
      left: 0,
      zIndex: -1,
      justifyContent: 'space-between',
    }
  }

  return (
    <div style={styles.container}>
      <HeaderMobile />

      <MobileRouter />

      <Footer />
    </div>
  )
}

export default Mobile;
