import React, { Component } from 'react';

import DesktopBreakpoint from './components/responsiveUtilites/desktopBreakpoint';
import PhoneBreakpoint from './components/responsiveUtilites/mobileBreakpoint';
import TabletBreakpoint from './components/responsiveUtilites/tabletBreakpoint';

import Desktop from './components/Platforms/Desktop';
import Mobile from './components/Platforms/Mobile';
import Tablet from './components/Platforms/Tablet';

class App extends Component {
  render() {
    return (
      <div className="App">
        <DesktopBreakpoint>
          <Desktop />
        </DesktopBreakpoint>

        <TabletBreakpoint>
          <Tablet />
        </TabletBreakpoint>

        <PhoneBreakpoint>
          <Mobile />
        </PhoneBreakpoint>
      </div>
    );
  }
}

export default App;
