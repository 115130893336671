import React from 'react';
import { Switch, Route } from 'react-router-dom';

import HomeTablet from '../../Tablet/HomeTablet';
import KontaktsTablet from '../../Tablet/KontaktsTablet';
import Impressum from '../Impressum';
import Datenschutz from '../Datenschutz';

const TabletRouter = () => (
    <main>
        <Switch>
            <Route exact path='/' component={HomeTablet} />
            <Route path='/kontakts' component={KontaktsTablet} />
            <Route path='/impressum' component={Impressum} />
            <Route path='/datenschutz' component={Datenschutz} />
        </Switch>
    </main>
)

export default TabletRouter;
