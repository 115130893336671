import React from 'react'
import Nav from 'react-bootstrap/Nav';

const Datenschutz = () => {

    return (
        <div style={styles.container} >
            <div>
                <b>Datenschutzerklärung</b>
                <p>
                    Wir freuen uns über Ihr Interesse an unserer Website wiweine.de.
                    Der Schutz Ihrer Privatsphäre ist für uns sehr wichtig.
                    Nachstehend informieren wir Sie ausführlich über den Umgang mit Ihren Daten.
                </p>
            </div>

            <div>
                <b>Speicherung von Zugriffsdaten in Server-Logfiles</b>
                <p>
                    Sie können unsere Webseiten besuchen,
                    ohne Angaben zu Ihrer Person zu machen.
                    Wir speichern lediglich Zugriffsdaten in sogenannten Server-Logfiles,
                    wie z.B. den Namen der angeforderten Datei, Datum und Uhrzeit des Abrufs,
                    übertragene Datenmenge und den anfragenden Provider.
                    Diese Daten werden ausschließlich zur Sicherstellung
                    eines störungsfreien Betriebs der Seite und zur Verbesserung
                    unseres Angebots ausgewertet und erlauben uns keinen Rückschluss auf Ihre Person.
                </p>
            </div>

            <div>
                <b>Datenerhebung und -verwendung zur Vertragsabwicklung und bei Eröffnung eines Kundenkontos</b>
                <p>Wir erheben personenbezogene Daten,
                    wenn Sie uns diese im Rahmen Ihrer Bestellung,
                    bei einer Kontaktaufnahme mit uns (z.B. per Kontaktformular oder E-Mail)
                    oder bei Eröffnung eines Kundenkontos freiwillig mitteilen.
                    Welche Daten erhoben werden,
                    ist aus den jeweiligen Eingabeformularen ersichtlich.
                    Wir verwenden die von ihnen mitgeteilten
                    Daten zur Vertragsabwicklung und Bearbeitung Ihrer Anfragen.
                    Nach vollständiger Abwicklung des Vertrages oder
                    Löschung Ihres Kundenkontos werden Ihre Daten für
                    die weitere Verwendung gesperrt und nach
                    Ablauf der steuer- und handelsrechtlichen
                    Aufbewahrungsfristen gelöscht, sofern Sie
                    nicht ausdrücklich in eine weitere Nutzung Ihrer Daten
                    eingewilligt haben oder wir uns eine darüber hinausgehende
                    Datenverwendung vorbehalten, die gesetzlich erlaubt
                    ist und über die wir Sie nachstehend informieren.
                    Die Löschung Ihres Kundenkontos ist jederzeit möglich
                    und kann entweder durch eine Nachricht an die unten
                    beschriebene Kontaktmöglichkeit oder über eine dafür
                    vorgesehene Funktion im Kundenkonto erfolgen.
                </p>
            </div>

            <div>
                <b>
                    Datenweitergabe zur Vertragserfüllung
                </b>
                <p>
                    Zur Vertragserfüllung geben wir
                    Ihre Daten an das mit der Lieferung beauftragte
                    Versandunternehmen weiter, soweit dies zur Lieferung bestellter
                    Waren erforderlich ist. Je nach dem, welchen Zahlungsdienstleister
                    Sie im Bestellprozess auswählen, geben wir zur Abwicklung
                    von Zahlungen die hierfür erhobenen Zahlungsdaten an das
                    mit der Zahlung beauftragte Kreditinstitut und ggf.
                    von uns beauftragte Zahlungsdienstleister weiter bzw.
                    an den ausgewählten Zahlungsdienst. Zum Teil erheben
                    die ausgewählten Zahlungsdienstleister diese Daten auch
                    selbst, soweit Sie dort ein Konto anlegen.
                    In diesem Fall müssen Sie sich im Bestellprozess mit
                    Ihren Zugangsdaten bei dem Zahlungsdienstleister anmelden.
                    Es gilt insoweit die Datenschutzerklärung des jeweiligen Zahlungsdienstleisters.
                </p>
            </div>

            <div>
                <b>
                    Datenverwendung bei Anmeldung zum E-Mail-Newsletter
                </b>
                <p>
                    Wenn Sie sich zu unserem Newsletter anmelden,
                    verwenden wir die hierfür erforderlichen oder
                    gesondert von Ihnen mitgeteilten Daten, um
                    Ihnen regelmäßig unseren E-Mail-Newsletter
                    entsprechend Ihrer Einwilligung zuzusenden.
                    Die Abmeldung vom Newsletter ist jederzeit möglich und
                    kann entweder durch eine Nachricht an die unten
                    beschriebene Kontaktmöglichkeit oder über einen
                    dafür vorgesehenen Link im Newsletter erfolgen.
                </p>
            </div>


            <div>
                <b>
                    Verwendung von Cookies
                </b>
                <p>
                    Um den Besuch unserer Website attraktiv
                    zu gestalten und die Nutzung bestimmter
                    Funktionen zu ermöglichen, verwenden wir
                    auf verschiedenen Seiten sogenannte Cookies.
                    Hierbei handelt es sich um kleine Textdateien,
                    die auf Ihrem Endgerät gespeichert werden.
                    Einige der von uns verwendeten Cookies werden nach
                    Ende der Browser-Sitzung, also nach Schließen Ihres
                    Browsers, wieder gelöscht (sog. Sitzungs-Cookies).
                    Andere Cookies verbleiben auf Ihrem Endgerät und ermöglichen uns,
                    Ihren Browser beim nächsten Besuch wiederzuerkennen (persistente Cookies).
                    Sie können Ihren Browser so einstellen,
                    dass Sie über das Setzen von Cookies informiert
                    werden und einzeln über deren Annahme entscheiden
                    oder die Annahme von Cookies für bestimmte Fälle oder
                    generell ausschließen. Bei der Nichtannahme von Cookies
                    kann die Funktionalität unserer Website eingeschränkt sein.
                </p>
            </div>


            <div>
                <b style={{ marginBottom: 25 }} >
                    Verwendung von Social Plugins von Facebook, Instagram unter Verwendung der Shariff-Lösung
                </b>
                <p>
                    Auf unserer Website werden Social Plugins („Plugins“)
                    von sozialen Netzwerken verwendet.
                    Um den Schutz Ihrer Daten beim Besuch unserer Website zu erhöhen,
                    sind die Plugins nicht uneingeschränkt,
                    sondern lediglich unter Verwendung eines HTML-Links (sogenannte „Shariff-Lösung“ von c‘t)
                    in die Seite eingebunden. Diese Einbindung gewährleistet,
                    dass beim Aufruf einer Seite unseres Webauftritts,
                    die solche Plugins enthält, noch keine Verbindung mit den Servern
                    des Anbieters des jeweiligen sozialen Netzwerks hergestellt wird.
                    Klicken Sie auf einen der Buttons, öffnet sich ein neues Fenster
                    Ihres Browsers und ruft die Seite des jeweiligen Diensteanbieters auf,
                    auf der Sie (ggf. nach Eingabe Ihrer Login-Daten) z.B.
                    den Like- oder Share-Button betätigen können.
                    Zweck und Umfang der Datenerhebung und die
                    weitere Verarbeitung und Nutzung der Daten
                    durch die Anbieter auf deren Seiten sowie Ihre d
                    iesbezüglichen Rechte und Einstellungsmöglichkeiten
                    zum Schutz Ihrer Privatsphäre entnehmen Sie
                    bitte den Datenschutzhinweisen der Anbieter.
                </p>
            </div>

            {/* Social links */}
            <div style={styles.socialLinks} >
                <Nav.Link href='http://www.facebook.com/policy.php' >
                    http://www.facebook.com/policy.php
                </Nav.Link>

                <Nav.Link href='https://help.instagram.com/155833707900388' >
                    https://help.instagram.com/155833707900388
                </Nav.Link>
            </div>

            <div>
                <b>
                    Auskunftsrecht und Kontaktmöglichkeit
                </b>
                <p>
                    Sie haben ein Recht auf unentgeltliche
                    Auskunft über die bei uns zu Ihrer Person
                    gespeicherten Daten sowie ggf. ein Recht auf Berichtigung,
                    Sperrung oder Löschung dieser Daten.
                    Bei Fragen zur Erhebung, Verarbeitung oder Nutzung
                    Ihrer personenbezogenen Daten, bei Auskünften,
                    Berichtigung, Sperrung oder Löschung von Daten
                    sowie Widerruf erteilter Einwilligungen oder
                    Widerspruch gegen eine bestimmte Datenverwendung
                    wenden Sie sich bitte direkt an uns über die Kontaktdaten in unserem Impressum.
                </p>

                <p>Als Betroffener haben Sie folgende Rechte:</p>

                <ul>
                    <li>
                        gemäß Art. 15 DSGVO das Recht, in dem dort bezeichneten Umfang
                        Auskunft über Ihre von uns verarbeiteten
                        personenbezogenen Daten zu verlangen;
                    </li>

                    <li>
                        gemäß Art. 16 DSGVO das Recht, unverzüglich
                        die Berichtigung unrichtiger oder Vervollständigung
                        Ihrer bei uns gespeicherten personenbezogenen Daten zu verlangen;
                    </li>

                    <li>
                        gemäß Art. 17 DSGVO das Recht,
                        die Löschung Ihrer bei uns gespeicherten personenbezogenen
                        Daten zu verlangen, soweit nicht die weitere Verarbeitung
                    </li>

                    <li>
                        - zur Ausübung des Rechts auf freie Meinungsäußerung und Information;
                    </li>

                    <li>
                        - zur Erfüllung einer rechtlichen Verpflichtung;
                    </li>

                    <li>
                        - aus Gründen des öffentlichen Interesses oder
                    </li>

                    <li>
                        - zur Geltendmachung, Ausübung oder Verteidigung von Rechtsansprüchen erforderlich ist;
                    </li>

                    <li>
                        gemäß Art. 18 DSGVO das Recht, die Einschränkung
                        der Verarbeitung Ihrer personenbezogenen
                        Daten zu verlangen, soweit
                    </li>

                    <li>
                        - die Richtigkeit der Daten von Ihnen bestritten wird;
                    </li>

                    <li>
                        - die Verarbeitung unrechtmäßig ist, Sie aber deren Löschung ablehnen;
                    </li>

                    <li>
                        - wir die Daten nicht mehr benötigen,
                        Sie diese jedoch zur Geltendmachung,
                        Ausübung oder Verteidigung von Rechtsansprüchen benötigen oder
                    </li>

                    <li>
                        - Sie gemäß Art. 21 DSGVO Widerspruch
                        gegen die Verarbeitung eingelegt haben;
                    </li>

                    <li>
                        gemäß Art. 20 DSGVO das Recht,
                        Ihre personenbezogenen Daten, die Sie uns bereitgestellt haben,
                        in einem strukturierten, gängigen und maschinenlesebaren
                        Format zu erhalten oder die Übermittlung an einen anderen
                        Verantwortlichen zu verlangen;
                    </li>

                    <li>
                        gemäß Art. 77 DSGVO das Recht, sich bei einer Aufsichtsbehörde zu beschweren.
                        In der Regel können Sie sich hierfür an die Aufsichtsbehörde
                        Ihres üblichen Aufenthaltsortes oder Arbeitsplatzes
                        oder unseres Unternehmenssitzes wenden."
                    </li>
                </ul>

                {/* ask about ampersants */}

                <div style={styles.linksAfterList} >
                    <Nav.Link href='http://shop.trustedshops.de/rechtstexte-kostenlos?__hstc=34495550.e5564b963f7b36444803b9e916176ebd.1480404478722.1480404478722.1480404478722.1&__hssc=34495550.19.1480404478722&__hsfp=3908935332' >
                        Datenschutzerklärung
                    </Nav.Link>
                    &nbsp; kostenlos erstellt mit&nbsp;
                    <Nav.Link href='http://www.trustedshops.com/' >
                        Trusted Shops
                    </Nav.Link>
                    &nbsp;echtstexter in Kooperation mit&nbsp;
                    <Nav.Link href='http://www.wbs-law.de/' >
                        Wilde Beuger Solmecke Rechtsanwälte
                    </Nav.Link>
                </div>

            </div>

            <div>
                <b style={{ fontSize: 25 }} >
                    Datenschutzerklärung für die Nutzung von Google Analytics
                </b>
                <p>
                    Diese Website nutzt Funktionen des Webanalysedienstes Google Analytics.
                    Anbieter ist die Google Inc., 1600 Amphitheatre
                    Parkway Mountain View, CA 94043, USA.
                </p>

                <p>
                    Google Analytics verwendet so genannte "Cookies".
                    Das sind Textdateien, die auf Ihrem Computer
                    gespeichert werden und die eine Analyse der
                    Benutzung der Website durch Sie ermöglichen.
                    Die durch den Cookie erzeugten Informationen über
                    Ihre Benutzung dieser Website werden in der Regel
                    an einen Server von Google in den USA übertragen und dort gespeichert.
                </p>

                <p>
                    Mehr Informationen zum Umgang mit Nutzerdaten bei
                    Google Analytics finden Sie in der Datenschutzerklärung von Google:&nbsp;
                    <Nav.Link href='https://support.google.com/analytics/answer/6004245?hl=de' >
                        https://support.google.com/analytics/answer/6004245?hl=de
                    </Nav.Link>
                </p>
            </div>



            <div>
                <b>
                    Browser Plugin
                </b>
                <p>
                    Sie können die Speicherung der Cookies durch
                    eine entsprechende Einstellung Ihrer
                    Browser-Software verhindern; wir weisen
                    Sie jedoch darauf hin, dass Sie in diesem Fall
                    gegebenenfalls nicht sämtliche Funktionen dieser
                    Website vollumfänglich werden nutzen können.
                    Sie können darüber hinaus die Erfassung der durch
                    den Cookie erzeugten und auf Ihre Nutzung der Website
                    bezogenen Daten (inkl. Ihrer IP-Adresse) an Google
                    sowie die Verarbeitung dieser Daten durch Google verhindern,
                    indem Sie das unter dem folgenden Link verfügbare Browser-Plugin herunterladen und installieren:&nbsp;
                    <Nav.Link href='https://tools.google.com/dlpage/gaoptout?hl=de' >
                        https://tools.google.com/dlpage/gaoptout?hl=de
                    </Nav.Link>
                </p>
            </div>

            <div>
                <b>
                    Widerspruch gegen Datenerfassung
                </b>
                <p>
                    Sie können die Erfassung Ihrer Daten durch Google Analytics verhindern,
                     indem Sie auf folgenden Link klicken.
                     Es wird ein Opt-Out-Cookie gesetzt,
                     der die Erfassung Ihrer Daten bei
                     zukünftigen Besuchen dieser Website verhindert:&nbsp;
                    <Nav.Link href='javascript:gaOptout();' >
                        Google Analytics deaktivieren
                    </Nav.Link>
                </p>
            </div>

            <div>
                <b>
                    IP-Anonymisierung
                </b>
                <p>
                    Wir nutzen die Funktion "Aktivierung der IP-Anonymisierung"
                    auf dieser Webseite. Dadurch wird Ihre IP-Adresse von Google
                    jedoch innerhalb von Mitgliedstaaten der Europäischen Union
                    oder in anderen Vertragsstaaten des Abkommens über den
                    Europäischen Wirtschaftsraum zuvor gekürzt.
                    Nur in Ausnahmefällen wird die volle IP-Adresse
                    an einen Server von Google in den USA übertragen und
                    dort gekürzt. Im Auftrag des Betreibers dieser Website wird
                    Google diese Informationen benutzen, um Ihre Nutzung
                    der Website auszuwerten, um Reports über die
                    Websiteaktivitäten zusammenzustellen und um weitere mit
                    der Websitenutzung und der Internetnutzung verbundene
                    Dienstleistungen gegenüber dem Websitebetreiber zu
                    erbringen. Die im Rahmen von Google Analytics von
                    Ihrem Browser übermittelte IP-Adresse wird nicht
                    mit anderen Daten von Google zusammengeführt.
                </p>

                <div>
                    <i>
                        Quellenangabe:&nbsp;
                    </i>
                    <Nav.Link href='https://www.e-recht24.de/' >
                        e-recht24.de
                    </Nav.Link>
                </div>
            </div>

        </div>
    )
}

const styles = {
    container: {
        marginTop: 40,
        marginLeft: 20,
        marginRight: 20,
        marginBottom: 25,
        overflowX: 'hidden'
    },
    socialLinks: {
        display: 'flex', 
        flexDirection: 'column',
        marginBottom: 20
    },
    linksAfterList: {
        marginBottom: 15
    }
}

export default Datenschutz;
