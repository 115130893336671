import React from 'react';
import { Link } from 'react-router-dom';

import Navbar from 'react-bootstrap/Navbar';
import Nav from 'react-bootstrap/Nav';

const Footer = () => {

    const footerImage = require('../images/FooterImage.png');
    const facebook = require('../images/Facebook.png');
    const instagram = require('../images/Instagram.png');

    return (
        <Navbar style={styles.container} >
            <div style={styles.topFooterContainer} >
                <img
                    alt=""
                    src={footerImage}
                    width="100"
                    height="140"
                    style={{ marginTop: 20, marginLeft: 25 }}
                    className="d-inline-block align-bottom"
                />
                <div style={{ marginRight: 25 }} >
                    <div style={{ color: 'white', display: 'flex', justifyContent: 'flex-end', marginBottom: 10 }} >
                        folgt uns auf
                    </div>
                    <div>
                        <Nav.Link href='https://www.facebook.com/novellina.germany/?modal=admin_todo_tour' >
                            <img
                                alt=""
                                src={facebook}
                                width="75"
                                height="75"
                                style={{ marginRight: 10 }}
                                className="d-inline-block align-bottom"
                            />
                        </Nav.Link>

                        <Nav.Link href='https://www.instagram.com/novellina.de/?hl=de' >
                            <img
                                alt=""
                                src={instagram}
                                width="75"
                                height="75"
                                // style={{ marginTop: 20, marginLeft: 25 }}
                                className="d-inline-block align-bottom"
                            />
                        </Nav.Link>
                    </div>
                </div>
            </div>

            <div style={styles.bottomFooterContainer} >
                <div style={{ marginLeft: 25 }} >
                    <p style={{ color: 'white' }} >
                        Privacy Policy <br />
                        &copy; 2014-2019 Maxifood GmbH
                    </p>

                    <div style={{ color: 'white' }} >
                        All Rights Reserved
                    </div>
                </div>

                <div style={{ marginRight: 25, display: 'flex', flexDirection: 'column', alignItems: 'flex-end' }} >
                    <Link
                        to='/kontakts'
                        style={{ textDecoration: 'none' }}
                    >
                        <div style={{ color: 'white', }}>Kontakts</div>
                    </Link>

                    <Link
                        to='/impressum'
                        style={{ textDecoration: 'none' }}
                    >
                        <div style={{ color: 'white' }} >
                            Impressum
                        </div>
                    </Link>

                    <Link
                        to='/datenschutz'
                        style={{ textDecoration: 'none' }}
                    >
                        <div style={{ color: 'white' }} >
                            Datenschutz
                        </div>
                    </Link>

                </div>
            </div>
        </Navbar>
    )
}

const styles = {
    container: {
        borderTop: '1px solid black',
        position: 'relative',
        top: 'auto',
        bottom: 0,
        right: 0,
        left: 0,
        backgroundColor: 'rgb(65, 13, 13)'
    },
    topFooterContainer: {
        display: 'flex',
        flexDirection: 'row',
        justifyContent: 'space-between',
        alignItems: 'center'
    },
    bottomFooterContainer: {
        marginTop: 20,
        paddingBottom: 20,
        display: 'flex',
        justifyContent: 'space-between'
    }
}

export default Footer;
