import React from 'react';
import { Switch, Route } from 'react-router-dom';

import DesktopHome from '../../Desktop/DesktopHome';
import Kontakts from '../../Desktop/Kontakts';
import Impressum from '../Impressum';
import Datenschutz from '../Datenschutz';

const AppRouter = () => (
    <main>
        <Switch>
            <Route exact path='/' component={DesktopHome} />
            <Route path='/kontakts' component={Kontakts} />
            <Route path='/impressum' component={Impressum} />
            <Route path='/datenschutz' component={Datenschutz} />
        </Switch>
    </main>
)

export default AppRouter;
