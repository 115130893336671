import React from 'react';

import AppRouter from '../Routers/AppRouter';
import DesktopHeader from '../../Desktop/DeskropHeader';
import Footer from '../Footer';

const Desktop = () => {

    return (
        <div style={styles.container} >
            <DesktopHeader />

            <AppRouter />

            <Footer />
        </div>
    )
}

const styles = {
    container: {
        minHeight: '100%',
        width: '100%',
        height: 'auto',
    }
}

export default Desktop;
