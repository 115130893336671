// src/components/responsive_utilities/desktop_breakpoint.jsx
import React from 'react';
import Breakpoint from './breakpoint';

const DesktopBreakpoint = (props) => {
    return (
        <Breakpoint name='desktop'>
            {props.children}
        </Breakpoint>
    );
}

export default DesktopBreakpoint;
