import React from 'react';

const KontaktsMobile = () => {

    const KontaktsImage = require('../images/KontaktsImage.png')

    return (
        <div style={styles.container} >
            <div style={styles.imageContainer} >
                <img
                    alt=''
                    src={KontaktsImage}
                    width="80%"
                    height="113%"
                />
            </div>

            <div style={styles.contactsConrainer} >
                <div style={{ fontSize: 35 }} >
                    Kontaktieren Sie uns!
                </div>
                <div style={{ fontSize: 25 }} >
                    Unser Team wird Sie gerne beraten.
                </div>
                <div style={styles.requisites} >
                    <b>
                        Tel: 05251 / 3982990
                    </b>
                </div>
                <div style={styles.requisites} >
                    <b>
                        E-Mail: info@maxifood
                    </b>
                </div>
            </div>
        </div>
    )
}

const styles = {
    container: {
        display: 'flex',
        flexDirection: 'column',
        justifyContent: 'space-between',
        marginBottom: '30%', 

    },
    imageContainer: {
    },
    contactsConrainer: {
        display: 'flex',
        flexDirection: 'column',
        justifyContent: 'center',
        alignItems: 'center',

        // marginLeft: -200
    },
    requisites: {
        fontSize: 20,
        marginTop: 20
    }
}

export default KontaktsMobile;
