import React, { Component } from 'react';
import { ScrollTo } from 'react-scroll-to';
import Nav from 'react-bootstrap/Nav';

import IntroVideo from './IntroVideo';

class DesktopHome extends Component {

    render() {

        const Rosso = require('../images/Rosso.png');
        const Bianco = require('../images/Bianco.png');
        const Granat = require('../images/Granat.png');
        const Pesca = require('../images/Pesca.png');
        const Arrows = require('../images/Arrows.png');

        return (
            <div style={styles.container} >
                <div style={styles.topContainer} >
                    <b style={styles.mainTitle} >
                        Tradition direkt aus Italien!
                    </b>

                    <div style={styles.videoContainer} >
                        <IntroVideo />
                    </div>

                    <div style={styles.mainText} >
                        <b >
                            Fragolino&nbsp;– der Name dieses rot leuchtenden,
                           lieblichen Perlweins bedeutet übersetzt „kleine Erdbeere“.
                           Und wie eine kleine Erdbeere riecht und schmeckt er auch.
                           Das Bukett des rot strahlenden Fragolino Rotwein ist voll
                           von intensiven Erdbeernoten. Am Gaumen zeigt sich dieses
                           Aromenbild ebenfalls und wird von einer zarten und dezent-frischen
                           Säure und einem feinen Prickeln untermalt.
                           Wenn man einen guten Fragolino Rotwein zum ersten Mal trinkt,
                           dann kann man kaum glauben, dass dieser Wein von sich aus und ohne
                           jeglichen Zusatz von zugeführten Geschmacksstoffen so deutlich nach Erdbeeren schmeckt.
                           Der aus dem Nordosten Italiens stammende Fragolino Rotwein besteht aus der roten Rebsorte
                           Vitis,Labrusca die auch unter dem Namen „Erdbeerrebe“ (uva fragola) bekannt ist.
                            Es existiert auch die Bezeichnung „amerikanische Rebe“ (uva americana) für sie.
                            In Deutschland ist die Rebe vielerorts als „Isabella“ bekannt.
                            Der Forschung zufolge wurden die Trauben für den Fragolino Rotwein im 19.
                            Jahrhundert südlich der Alpen kultiviert.
                        </b>
                    </div>
                </div>

                <div style={{ display: 'flex', justifyContent: 'center' }} >
                    <ScrollTo>
                        {({ scrollTo }) => (
                            <a onClick={() => scrollTo({ x: 20, y: 900 })}>
                                <img
                                    alt=""
                                    src={Arrows}
                                    width="30"
                                    height="50"
                                    style={{ marginBottom: -30, }}
                                    className="d-inline-block align-bottom"
                                /></a>
                        )}
                    </ScrollTo>
                </div>

                <div style={styles.bottomContainer} >
                    <div style={styles.wineAndDescriptionContainer} >
                        <div style={{ ...styles.bottleContainer, ...{ border: '2px solid rgb(167, 13, 12)', } }} >
                            <div style={styles.leftWineTitle} >
                                <b style={styles.wineTitle} >
                                    Rosso<br />
                                </b>
                            </div>
                            <img
                                alt=""
                                src={Rosso}
                                width="150"
                                height="500"
                            />
                        </div>

                        <div style={styles.descriptionAndButtonContainer} >
                            <div style={styles.leftDescription} >
                                Dieser erfrischend liebliche Perlwein aus dem Hause Novellina,<br />
                                ist das ideale Getränk für die weiblichen Gäste Ihrer Party.<br />
                                In der Farbe Rubinrot, mit rosafarbenen Reflexen.<br />
                                Im Duft hauptsächlich fruchtige Aromen nach Walderdbeeren.<br />
                                Am Gaumen süß, frisch und lebendig,<br />
                                mit intensiven Noten nach Erdbeeren und Waldfrüchten.<br />
                                Er ist ein ausgezeichneter Aperitif und ein herrlicher Begleiter zum Desserts .<br />
                            </div>

                            <Nav.Link href='https://wiweine.de/fruchtweine/fragolino-rosso-novellina-0-75-ltr.html' >
                                <button onClick={this.goToRosso} style={{ ...styles.buttonStyle, ...{ border: '2px solid rgb(167, 13, 12)' } }} >
                                    <b style={styles.buttonTitle} >zum Shop</b>
                                </button>
                            </Nav.Link>
                        </div>
                    </div>

                    <div style={styles.wineAndDescriptionContainer} >
                        <div style={styles.descriptionAndButtonContainer} >
                            <div style={styles.rightDescription}>
                                Fragolino ist Kult!<br />
                                Der Geheimtipp unter Italienurlaubern wird vor allem<br />
                                zum Aperitif oder als Dessertwein gereicht.<br />
                                Er ist wunderbar spritzig und fruchtig und<br />
                                duftet unwiderstehlich nach Erdbeeren.<br />
                                Die Norditalienische Novellina-Rebe hat einen<br />
                                sehr intensiven Erdbeer-Geschmack.<br />
                                Nicht umsonst heißt sie Fragolino - von Novellina, Erdbeere.<br />
                                Am Gaumen ist der Fragolino fruchtig und mild,<br />
                                im Abgang herrlich süffig – das ideale Getränk für Ihre Sommerparty.<br />
                            </div>

                            <Nav.Link href='https://wiweine.de/fruchtweine/fragolino-bianco-novellina-0-75-ltr.html' >
                                <button style={{ ...styles.buttonStyle, ...{ border: '2px solid rgb(217, 186, 11)', } }} >
                                    <b style={styles.buttonTitle} >zum Shop</b>
                                </button>
                            </Nav.Link>
                        </div>

                        <div style={{ ...styles.bottleContainer, ...{ border: '2px solid rgb(217, 186, 11)', } }} >
                            <img
                                alt=""
                                src={Bianco}
                                width="150"
                                height="500"
                            />
                            <div style={styles.rightWineTitle} >
                                <b style={styles.wineTitle} >
                                    Bianco<br />
                                </b>
                            </div>
                        </div>
                    </div>

                    <div style={styles.wineAndDescriptionContainer} >
                        <div style={{ ...styles.bottleContainer, ...{ border: '2px solid rgb(243, 196, 181)' } }} >
                            <div style={styles.leftWineTitle} >
                                <b style={styles.wineTitle} >
                                    Granat<br />
                                </b>
                            </div>
                            <img
                                alt=""
                                src={Granat}
                                width="150"
                                height="500"
                            />
                        </div>

                        <div style={styles.descriptionAndButtonContainer} >
                            <div style={styles.leftDescription} >
                                Granatapfel – die Powerfrucht für Trendsetter.<br />
                                Wein, prickeln {'&'} Fruchtsaft: Zusammen ergibt das einen prickelnd,<br />
                                fruchtig-herben Genuss mit inspirierendem Geschmack nach mehr.<br />

                                Egal ob als Aperitif oder als Cocktail,<br />
                                ob leicht gekühlt oder auf Eis serviert und mit Früchten verfeinert.<br />
                                Dieser Novellina Fruchtwein überzeugt als exotisches Geschmackserlebnis.<br />
                            </div>

                            <Nav.Link href='https://wiweine.de/fruchtweine/granatino-rose-novellina-0-75-ltr.html' >
                                <button style={{ ...styles.buttonStyle, ...{ border: '2px solid rgb(243, 196, 181)' } }} >
                                    <b style={styles.buttonTitle} >zum Shop</b>
                                </button>
                            </Nav.Link>
                        </div>
                    </div>

                    <div style={styles.wineAndDescriptionContainer} >
                        <div style={styles.descriptionAndButtonContainer} >
                            <div style={styles.rightDescription}>
                                Als harmonisch-fruchtiges Geschmackserlebnis<br />
                                holt Novellina Fruchtwein Pfirsich ,den Sommer ins Glas.<br />

                                Ausgewählte Grundweine angereichert mit Pfirsichsaft –<br />
                                Novellina Fruchtwein Mellow,<br />
                                macht einfach gute Laune und kommt in jeder Runde gut an.<br />
                                Egal ob mit Freunden oder Familie, diese gelb-orange Kombination,<br />
                                mit goldenen Reflexen schimmernde Fruchtsorte überzeugt jeden.<br />
                                Der Duft nach frischer Melone und eine dezente Pfirsich Note;<br />
                                lassen Novellina Fruchtwein, zu einem exotischen Geschmackserlebnis werden.<br />
                            </div>

                            <Nav.Link href='https://wiweine.de/fruchtweine/pesca-mellow-novellina-0-75-ltr.html' >
                                <button style={{ ...styles.buttonStyle, ...{ border: '2px solid rgb(195, 171, 13)' } }} >
                                    <b style={styles.buttonTitle} >zum Shop</b>
                                </button>
                            </Nav.Link>
                        </div>

                        <div style={{ ...styles.bottleContainer, ...{ border: '2px solid rgb(195, 171, 13)' } }} >
                            <img
                                alt=""
                                src={Pesca}
                                width="150"
                                height="500"
                            />
                            <div style={styles.rightWineTitle} >
                                <b style={styles.wineTitle} >
                                    Pesca<br />
                                </b>
                            </div>
                        </div>
                    </div>

                </div>
            </div>
        )
    }
}

const styles = {
    container: {
        height: '100%'
    },
    topContainer: {
        display: 'flex',
        justifyContent: 'center',
        flexDirection: 'column',
        // alignItems: 'center',
        marginTop: 25,
        marginBottom: 25
    },
    bottomContainer: {
        backgroundColor: 'rgb(247, 245, 245)',
        display: 'flex',
        flexDirection: 'column',
        justifyContent: 'space-between',
        paddingLeft: 80,
        paddingRight: 80,
        paddingTop: 50,
        paddingBottom: 50
    },
    bottleContainer: {
        display: 'flex',
        flexDirection: 'row-reverse',
        justifyContent: 'center',
        alignItems: 'center',
        width: '30%',
        padding: 25,
        borderRadius: '60px',
    },
    buttonStyle: {
        marginTop: 25,
        borderRadius: '60px',
        padding: 12,
        paddingLeft: 25,
        paddingRight: 25,
        backgroundColor: 'white'
    },
    wineAndDescriptionContainer: {
        display: 'flex',
        flexDirection: 'row',
        justifyContent: 'space-between',
        marginTop: 120
    },
    mainTitle: {
        fontSize: 40,
        display: 'flex',
        alignSelf: 'center'
    },
    videoContainer: {
        marginTop: 30,
        marginBottom: 30,
        display: 'flex',
        justifyContent: 'center'
    },
    mainText: {
        display: 'flex',
        alignSelf: 'center',
        width: '60%'
    },
    descriptionAndButtonContainer: {
        display: 'flex',
        alignItems: 'center',
        flexDirection: 'column',
        justifyContent: 'center'
    },
    rightWineTitle: {
        textAlign: 'right'
    },
    leftWineTitle: {
        textAlign: 'left'
    },
    buttonTitle: {
        fontSize: 20
    },
    wineTitle: {
        fontSize: 50
    },
    leftDescription: {
        textAlign: 'left',
    },
    rightDescription: {
        textAlign: 'right',
    }
}

export default DesktopHome;
