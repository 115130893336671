import React from 'react';
import { Link } from 'react-router-dom';
import Navbar from 'react-bootstrap/Navbar';
import Nav from 'react-bootstrap/Nav'

const DeskropHeader = () => {

    const logo = require('../images/logo.png');

    return (
        <div>
            <Navbar sticky='bottom' bg="light" variant="light" style={styles.container} >
                <Navbar.Brand href="#home">
                    <img
                        alt=""
                        src={logo}
                        width="150"
                        height="30"
                        style={{ marginTop: 20, marginLeft: 10, marginBottom: 10 }}
                        className="d-inline-block align-top"
                    />
                </Navbar.Brand>
                <Nav className="mr-auto" style={styles.navLink} >
                    <Link
                        to='/'
                        style={styles.navLink} >
                        <b style={{ color: 'black' }}>Startseite</b>
                    </Link>
                    <Nav.Link
                        href='https://wiweine.de/'
                        style={styles.navLink} >
                        <b style={{ color: 'black', textDecoration: 'none' }} >Shop</b>
                    </Nav.Link>
                    <Link
                        to='/kontakts'
                        style={{ textDecoration: 'none' }}>
                        <b style={{ color: 'black' }} >Kontakt</b>
                    </Link>
                </Nav>

            </Navbar>

        </div>
    )
}

const styles = {
    container: {
        borderBottom: '1px solid black',
        display: 'flex',
        flexDirection: 'row',
        alignItems: 'center',
        justifyContent: 'space-between',
        marginLeft: 25,
        marginRight: 25,
    },
    navLink: {
        marginRight: 20,
        textDecoration: 'none'
    }
}

export default DeskropHeader;
