import React, { Component } from 'react';
import Nav from 'react-bootstrap/Nav';

import MobileVideo from './MobileVideo';

class HomeMobile extends Component {
    render() {

        const Rosso = require('../images/Rosso.png');
        const Bianco = require('../images/Bianco.png');
        const Granat = require('../images/Granat.png');
        const Pesca = require('../images/Pesca.png');

        return (

            <div style={styles.container} >
                <div style={styles.topContainer} >
                    <b style={styles.mainTitle} >
                        Tradition direkt aus Italien!
                    </b>

                    <div style={styles.videoContainer} >
                        <MobileVideo />
                    </div>

                    <b style={styles.mainText} >
                        Fragolino – der Name dieses rot leuchtenden,
                       lieblichen Perlweins bedeutet übersetzt „kleine Erdbeere“.
                       Und wie eine kleine Erdbeere riecht und schmeckt er auch.
                       Das Bukett des rot strahlenden Fragolino Rotwein ist voll
                       von intensiven Erdbeernoten. Am Gaumen zeigt sich dieses
                       Aromenbild ebenfalls und wird von einer zarten und dezent-frischen
                       Säure und einem feinen Prickeln untermalt.
                       Wenn man einen guten Fragolino Rotwein zum ersten Mal trinkt,
                       dann kann man kaum glauben, dass dieser Wein von sich aus und ohne
                       jeglichen Zusatz von zugeführten Geschmacksstoffen so deutlich nach Erdbeeren schmeckt.
                       Der aus dem Nordosten Italiens stammende Fragolino Rotwein besteht aus der roten Rebsorte
                       Vitis, Labrusca, die auch unter dem Namen „Erdbeerrebe“ (uva fragola) bekannt ist.
                        Es existiert auch die Bezeichnung „amerikanische Rebe“ (uva americana) für sie.
                        In Deutschland ist die Rebe vielerorts als „Isabella“ bekannt.
                        Der Forschung zufolge wurden die Trauben für den Fragolino Rotwein im 19.
                        Jahrhundert südlich der Alpen kultiviert.
                    </b>
                </div>

                <div style={styles.bottomContainer} >
                    <div style={styles.wineAndDescriptionContainer} >
                        <div style={{ ...styles.bottleContainer, ...{ border: '2px solid rgb(167, 13, 12)', } }} >
                            <div style={styles.leftWineTitle} >
                                <b style={styles.wineTitle} >
                                    Rosso<br />
                                </b>
                            </div>
                            <img
                                alt=""
                                src={Rosso}
                                width="18%"
                                height="170"
                            />
                        </div>

                        <div style={styles.descriptionAndButtonContainer} >
                            <div style={styles.wineDescription}>
                                Dieser erfrischend liebliche Perlwein aus dem Hause Novellina,
                                ist das ideale Getränk für die weiblichen Gäste Ihrer Party.
                                In der Farbe Rubinrot, mit rosafarbenen Reflexen.
                                Im Duft hauptsächlich fruchtige Aromen nach Walderdbeeren.
                                Am Gaumen süß, frisch und lebendig,
                                mit intensiven Noten nach Erdbeeren und Waldfrüchten.
                                Er ist ein ausgezeichneter Aperitif und ein herrlicher Begleiter zum Desserts.
                            </div>

                            <Nav.Link href='https://wiweine.de/fruchtweine/fragolino-rosso-novellina-0-75-ltr.html' >
                                <button style={{ ...styles.buttonStyle, ...{ border: '2px solid rgb(167, 13, 12)' } }} >
                                    <b style={styles.buttonTitle} >zum Shop</b>
                                </button>
                            </Nav.Link>
                        </div>
                    </div>

                    <div style={styles.wineAndDescriptionContainer} >
                        <div style={{ ...styles.bottleContainer, ...{ border: '2px solid rgb(217, 186, 11)', } }} >
                            <img
                                alt=""
                                src={Bianco}
                                width="18%"
                                height="170"
                            />
                            <div style={styles.rightWineTitle} >
                                <b style={styles.wineTitle} >
                                    Bianco<br />
                                </b>
                            </div>
                        </div>
                        <div style={styles.descriptionAndButtonContainer} >
                            <div style={styles.wineDescription}>
                                Fragolino ist Kult!
                                Der Geheimtipp unter Italienurlaubern wird vor allem
                                zum Aperitif oder als Dessertwein gereicht
                                Er ist wunderbar spritzig und fruchtig und
                                duftet unwiderstehlich nach Erdbeeren.
                                Die Norditalienische Novellina-Rebe hat einen
                                sehr intensiven Erdbeer-Geschmack.
                                Nicht umsonst heißt sie Fragolino - von Novellina, Erdbeere.
                                Am Gaumen ist der Fragolino fruchtig und mild,
                                im Abgang herrlich süffig – das ideale Getränk für Ihre Sommerparty.
                            </div>

                            <Nav.Link href='https://wiweine.de/fruchtweine/fragolino-bianco-novellina-0-75-ltr.html' >
                                <button style={{ ...styles.buttonStyle, ...{ border: '2px solid rgb(217, 186, 11)', } }} >
                                    <b style={styles.buttonTitle} >zum Shop</b>
                                </button>
                            </Nav.Link>
                        </div>

                    </div>

                    <div style={styles.wineAndDescriptionContainer} >
                        <div style={{ ...styles.bottleContainer, ...{ border: '2px solid rgb(243, 196, 181)', } }} >
                            <div style={styles.leftWineTitle} >
                                <b style={styles.wineTitle} >
                                    Granat
                                </b>
                            </div>
                            <img
                                alt=""
                                src={Granat}
                                width="18%"
                                height="170"
                            />
                        </div>

                        <div style={styles.descriptionAndButtonContainer} >
                            <div style={styles.wineDescription} >
                                Granatapfel – die Powerfrucht für Trendsetter.
                                Wein, prickeln {'&'} Fruchtsaft: Zusammen ergibt das einen prickelnd,
                                fruchtig-herben Genuss mit inspirierendem Geschmack nach mehr.

                                Egal ob als Aperitif oder als Cocktail,
                                ob leicht gekühlt oder auf Eis serviert und mit Früchten verfeinert.
                                Dieser Novellina Fruchtwein überzeugt als exotisches Geschmackserlebnis.
                            </div>

                            <Nav.Link href='https://wiweine.de/fruchtweine/granatino-rose-novellina-0-75-ltr.html' >
                                <button style={{ ...styles.buttonStyle, ...{ border: '2px solid rgb(243, 196, 181)', } }} >
                                    <b style={styles.buttonTitle} >zum Shop</b>
                                </button>
                            </Nav.Link>
                        </div>
                    </div>

                    <div style={styles.wineAndDescriptionContainer} >
                        <div style={{ ...styles.bottleContainer, ...{ border: '2px solid rgb(195, 171, 13)' } }} >
                            <img
                                alt=""
                                src={Pesca}
                                width="18%"
                                height="170"
                            />
                            <div style={styles.rightWineTitle} >
                                <b style={styles.wineTitle} >
                                    Pesca<br />
                                </b>
                            </div>
                        </div>

                        <div style={styles.descriptionAndButtonContainer} >
                            <div style={styles.wineDescription}>
                                Als harmonisch-fruchtiges Geschmackserlebnis
                                holt Novellina Fruchtwein Pfirsich ,den Sommer ins Glas.

                                Ausgewählte Grundweine angereichert mit Pfirsichsaft –
                                Novellina Fruchtwein Mellow,
                                macht einfach gute Laune und kommt in jeder Runde gut an.
                                Egal ob mit Freunden oder Familie, diese gelb-orange Kombination,
                                mit goldenen Reflexen schimmernde Fruchtsorte überzeugt jeden.
                                Der Duft nach frischer Melone und eine dezente Pfirsich Note;
                                lassen Novellina Fruchtwein, zu einem exotischen Geschmackserlebnis werden.
                            </div>

                            <Nav.Link href='https://wiweine.de/fruchtweine/pesca-mellow-novellina-0-75-ltr.html' >
                                <button style={{ ...styles.buttonStyle, ...{ border: '2px solid rgb(195, 171, 13)' } }} >
                                    <b style={styles.buttonTitle} >zum Shop</b>
                                </button>
                            </Nav.Link>
                        </div>
                    </div>

                </div>
            </div>

        )
    }
}

const styles = {
    container: {
        height: '100%',
    },
    topContainer: {
        display: 'flex',
        justifyContent: 'center',
        flexDirection: 'column',
        alignItems: 'center',
        marginTop: 25,
        marginBottom: 25,
    },
    bottomContainer: {
        backgroundColor: 'rgb(247, 245, 245)',
        display: 'flex',
        flexDirection: 'column',
        justifyContent: 'center',
        paddingLeft: 10,
        paddingRight: 10,
        paddingTop: 30,
        paddingBottom: 50,
    },
    bottleContainer: {
        display: 'flex',
        flexDirection: 'row-reverse',
        alignSelf: 'center',
        justifyContent: 'center',
        alignItems: 'center',
        padding: 10,
        borderRadius: '60px',
        width: '75%'
    },
    buttonStyle: {
        marginTop: 25,
        borderRadius: '60px',
        padding: 12,
        paddingLeft: 25,
        paddingRight: 25,
        backgroundColor: 'white'
    },
    wineAndDescriptionContainer: {
        display: 'flex',
        flexDirection: 'column',
        justifyContent: 'center',
        marginTop: 30
    },
    mainTitle: {
        fontSize: 28,
        textAlign: 'center'
    },
    videoContainer: {
        marginTop: 30,
        marginBottom: 30,
        display: 'flex',
        justifyContent: 'center'
    },
    mainText: {
        textAlign: 'left',
        marginLeft: 15,
        marginRight: 15
    },
    descriptionAndButtonContainer: {
        display: 'flex',
        alignItems: 'center',
        flexDirection: 'column',
        justifyContent: 'center'
    },
    rightWineTitle: {
        textAlign: 'right'
    },
    leftWineTitle: {
        textAlign: 'left'
    },
    buttonTitle: {
        fontSize: 20
    },
    wineTitle: {
        fontSize: 25
    },
    wineDescription: {
        textAlign: 'center',
        marginTop: 15
    },
}

export default HomeMobile;
