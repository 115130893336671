
import React from 'react'

import TabletRouter from '../Routers/TabletRouter';
import HeaderTablet from '../../Tablet/HeaderTablet';
import Footer from '../Footer';

const Tablet = () => {

  return (
    <div style={styles.container}>
        <HeaderTablet />

        <TabletRouter />

        <Footer />
    </div>
  )
}

const styles = {
  container: {
    backgroundPosition: 'center',
    backgroundRepeat: 'no-repeat',
    backgroundSize: 'cover',
    minHeight: '100%',
    width: '100%',
    height: 'auto',
    top: 0,
    left: 0,
    zIndex: -1,
    justifyContent: 'space-between',
  }
}

export default Tablet;
