import React from 'react';
import { Switch, Route } from 'react-router-dom';

import HomeMobile from '../../Mobile/HomeMobile';
import KontaktsMobile from '../../Mobile/KontaktsMobile';
import Impressum from '../Impressum';
import Datenschutz from '../Datenschutz';


const MobileRouter = () => (
    <main>
        <Switch>
            <Route exact path='/' component={HomeMobile} />
            <Route path='/kontakts' component={KontaktsMobile} />
            <Route path='/impressum' component={Impressum} />
            <Route path='/datenschutz' component={Datenschutz} />
        </Switch>
    </main>
)

export default MobileRouter;
