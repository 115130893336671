import React from 'react';
import {
    Player,
    ControlBar,
    ReplayControl,
    ForwardControl,
    CurrentTimeDisplay,
    TimeDivider,
    PlaybackRateMenuButton,
    VolumeMenuButton,
    BigPlayButton
} from 'video-react';

const TabletVideo = () => {

    const Poster = require('../images/poster.png');
    const Frago = require('../images/Frago1.mov');

    return (
        <Player
            fluid={false}
            playsInline
            poster={Poster}
            width={400}
            
        >
            <source src={Frago} />
            <BigPlayButton position="center" />
            <ControlBar>
                <ReplayControl seconds={10} order={1.1} />
                <ForwardControl seconds={30} order={1.2} />
                <CurrentTimeDisplay order={4.1} />
                <TimeDivider order={4.2} />
                <PlaybackRateMenuButton rates={[5, 2, 1, 0.5, 0.1]} order={7.1} />
                <VolumeMenuButton disabled />
            </ControlBar>
        </Player>
    )
}

export default TabletVideo;
