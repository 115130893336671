import React, { useState } from 'react';
import { Link } from 'react-router-dom'
import Navbar from 'react-bootstrap/Navbar';
import Nav from 'react-bootstrap/Nav';
import Button from 'react-bootstrap/Button';
import ListGroup from 'react-bootstrap/ListGroup'
import Drawer from 'react-motion-drawer';
import { MdMenu } from 'react-icons/md';

const HeaderTablet = () => {

    const [opened, setOpen] = useState(false);

    const handleDrawerToggle = () => {
        setOpen(!opened)
    };

    const logo = require('../images/logo.png');

    return (
        <div >
            <Navbar sticky='top' bg='light' variant='light' style={styles.mainContainer} >

                <Link to='/'>
                    <img
                        alt=''
                        src={logo}
                        width='150'
                        height='30'
                        style={{ marginTop: 20, marginLeft: 10, marginBottom: 10 }}
                    />
                </Link>

                <nav>
                    <Drawer
                        open={opened}
                        width={'50%'}
                        onChange={handleDrawerToggle}
                        drawerStyle={styles.drawerStyle}
                        right={true}
                    >
                        <ListGroup
                            as='ul'
                            style={styles.drawerContainer}
                        >
                            <ListGroup.Item onClick={handleDrawerToggle} >
                                <Link
                                    to='/'
                                    style={{ textDecoration: 'none' }} >
                                    <div style={styles.navLinkLabel}>
                                        Startseite
                                    </div>
                                </Link>
                            </ListGroup.Item>

                            <ListGroup.Item onClick={handleDrawerToggle} >
                                <Nav.Link
                                    href='https://wiweine.de/'
                                    style={{ textDecoration: 'none' }}
                                >
                                    <div style={styles.navLinkLabel}>
                                        Shop
                                    </div>
                                </Nav.Link>
                            </ListGroup.Item>

                            <ListGroup.Item onClick={handleDrawerToggle} >
                                <Link
                                    to='/kontakts'
                                    style={{ textDecoration: 'none' }} >
                                    <div style={styles.navLinkLabel}>
                                        Kontakts
                                    </div>
                                </Link>
                            </ListGroup.Item>
                        </ListGroup>

                    </Drawer>
                </nav>

                <Button
                    style={styles.menuButton}
                    onClick={() => handleDrawerToggle()}
                    >
                    <MdMenu
                        size='3em'
                    />
                </Button>
            </Navbar>

        </div>
    )
}

const styles = {
    logoStyle: {
        marginLeft: 50,
        marginRIght: 15
    },
    drawerStyle: {
        backgroundColor: 'rgb(65, 13, 13)',
        opacity: 0.6,
    },
    drawerContainer: {
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'center',
        alignSelf: 'center',
        justifyContent: 'center',
        marginTop: '100%'
    },
    menuButton: {
        color: 'black',
    },
    mainContainer: {
        borderBottom: '1px solid black',
        display: 'flex',
        flexDirection: 'row',
        alignItems: 'center',
        justifyContent: 'space-between',
        marginLeft: 25,
        marginRight: 25,
        position: 'relative',
        overflow: 'hidden'
    },
    listItemTitle: {
        display: 'flex',
        justifyContent: 'center'
    },
    navLinkLabel: {
        color: 'white',
        fontSize: 35,
    }
};

export default HeaderTablet;
