import React from 'react'
import Nav from 'react-bootstrap/Nav';

const Impressum = () => {

    return (
        <div style={styles.container} >

            <p>Maxifood Handels- und Vertriebs GmbH</p>

            <div>
                An der Talle 22<br />
                33102 Paderborn<br />
                Deutschland<br />
            </div>

            <div>
                <div>
                    <p> Telefon: +49 (0) 5251 / 398 29 94</p>
                    <p>Fax: +49 (0) 5251 / 398 29 98</p>
                </div>
                <p> E-Mail: service@maxifood.de</p>
            </div>

            <div>

                <p>Vertreten durch: Tetyana Boccaccio, Sahra Boccaccio</p>

                <p>Handelsregister: Registergericht Paderborn, HRB 9604</p>

                <p>Umsatzsteuer-Identifikationsnummer: DE 815154441</p>

                <p>Inh. Verantwortlich nach § 5 Absatz 1 TMG: Tetyana Boccaccio</p>

                <p>Alternative Streitbeilegung gemäß Art. 14 Abs. 1 ODR-VO und § 36 VSBG: Die Europäische </p>

                <div>
                    Kommission stellt eine Plattform zur Online-Streitbeilegung (OS) bereit,<br />
                    die Sie unter &nbsp;
                    <Nav.Link href='http://ec.europa.eu/consumers/odr/'>http://ec.europa.eu/consumers/odr/</Nav.Link>
                    &nbsp;finden.<br />
                    Zur Teilnahme an einem Streitbeilegungsverfahren vor einer<br />
                    Verbraucherschlichtungsstelle sind wir nicht verpflichtet und nicht bereit.<br />
                </div>

            </div>
        </div>
    )
}

const styles = {
    container: {
        marginTop: 40,
        marginLeft: 40,
        marginBottom: '55%', 
    }
}

export default Impressum;
