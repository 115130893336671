import React from 'react';
import Breakpoint from './breakpoint';

const TabletBreakpoint = (props) => {
    return (
        <Breakpoint name='tablet'>
            {props.children}
        </Breakpoint>
    );
}

export default TabletBreakpoint;
